import React, { useState } from "react";
import { FormControl, InputLabel, Input, FormHelperText, TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

// 3rd party imports
import emailjs from "emailjs-com";
import { Alert } from "react-bootstrap";

// icons
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles()


const REACT_APP_SERVICE = "service_mssoxhk";
const REACT_APP_USER = "user_I7IUuB30pXyw5t0R7OAV2";
const REACT_APP_TEMPLATE = "template_677u2u7";


const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required()
})


export default function WaitlistSection() {
    const [status, setStatus] = useState(undefined);
    const { register, handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [loading, setLoading] = React.useState(false);


    const onSubmit = async (data) => {
        try {
            setLoading(true)
            const templateParams = {
                name: data.name,
                email: data.email,
            };
            await emailjs.send(
                REACT_APP_SERVICE,
                REACT_APP_TEMPLATE,
                templateParams,
                REACT_APP_USER
            ).then(() => {
                setStatus({ type: "success" });
                reset({ 
                    name:'',
                    email:''
                });
            }).catch((err) => {
                setStatus({ type: "error", err });
            });
            setLoading(false)
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-6/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div className="text-gray-800 text-center mb-3 font-bold">
                                    <small>Get Notified When We Launch!</small>
                                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                        <div className="container mx-auto px-4 mt-2 h-full">
                                                <Controller
                                                    fullWidth
                                                    name="name"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <TextField
                                                            label="Name"
                                                            placeholder="Please enter your name"
                                                            variant="outlined"
                                                            className="w-full"
                                                            margin="normal"
                                                            value={value}
                                                            onChange={onChange}
                                                            error={!!error}
                                                            helperText={error ? error.message : null}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    fullWidth
                                                    name="email"
                                                    control={control}
                                                    defaultValue=""
                                                    className="w-full"
                                                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                        <TextField
                                                            label="Email"
                                                            placeholder="Please enter your email"
                                                            variant="outlined"
                                                            className="w-full"
                                                            margin="normal"
                                                            value={value}
                                                            onChange={onChange}
                                                            error={!!error}
                                                            helperText={error ? error.message : null}
                                                            type="email"
                                                        />
                                                    )}
                                                />
                                            {/* <FormControl fullWidth>
                                                {!errors.name && <InputLabel htmlFor="name" >Your Name</InputLabel>}
                                                {errors.name && <InputLabel error htmlFor="name" >Your Name</InputLabel>}
                                                <Input name="name" className="mb-2" {...register('name', { required: true })} placeholder="Please enter your name"></Input>
                                                {errors.name && (<FormHelperText id="name-error" error>Please enter your name.</FormHelperText>)}
                                            </FormControl>
                                            <FormControl fullWidth variant="filled" className="w-full px-4 py-6">
                                                {!errors.email && <InputLabel htmlFor="email">Your Email</InputLabel>}
                                                {errors.email && <InputLabel error htmlFor="email">Your Email</InputLabel>}
                                                <Input name="email" {...register('email', { required: true })} placeholder="Please enter your email"></Input>
                                                {errors.email && (<FormHelperText id="email-error" error>Please enter a valid email address{ }</FormHelperText>)}
                                            </FormControl> */}
                                            <hr></hr>
                                            <div className="text-center mt-6">
                                                <button
                                                    className=" bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                    type="submit"
                                                >
                                                    Join Wait List
                                                    {loading && <CircularProgress className="absolute ml-2" size={24} />}
                                                </button>



                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {status?.type === 'success' && <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-green-600 border-0">
                            <div className="rounded-t px-3 py-3 ">
                                <div className="text-center text-black font-bold text-lg">
                                    <Alert variant='success'>🚀 Message sent, thanks! Click {' '}
                                        <Alert.Link href="/" className="text-white-2">here</Alert.Link> to return home.</Alert>
                                </div>
                            </div>
                        </div>}
                        {status?.type === 'error' && <div className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded-lg bg-red-600 border-0">
                            <div className="rounded-t px-3 py-3 ">
                                <div className="text-center text-white font-bold text-lg">
                                    <Alert variant='danger'>Something went wrong, either try again or return {' '}
                                        <Alert.Link href="/" className="text-black">Home</Alert.Link></Alert>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    );
}
import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Input } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import emailjs from "emailjs-com";

// icons
import EmailIcon from "@material-ui/icons/Email";
import SelectInput from "@material-ui/core/Select/SelectInput";

const REACT_APP_SERVICE = "service_mssoxhk";
const REACT_APP_USER = "user_I7IUuB30pXyw5t0R7OAV2";
const REACT_APP_TEMPLATE = "template_677u2u7";

export default function Register() {
  let history = useHistory();

  const toastifySuccess = () => {
    toast.success("🚀 Message sent, thanks!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      email: "",
    }
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    let data = { formInput };
    try {
      const templateParams = {
        name: Object.values(data)[0]["name"],
        email: Object.values(data)[0]["email"],
      };
      console.log(templateParams);
      await emailjs.send(
        REACT_APP_SERVICE,
        REACT_APP_TEMPLATE,
        templateParams,
        REACT_APP_USER
      );
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  const handleInput = (evt) => {
    const name = evt.target.name;
    const newValue = evt.target.value;
    setFormInput({ [name]: newValue });
  };

  const redirect = () => {
    history.push("/");
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-gray-500 text-center mb-3 font-bold">
                  <small>Get Notified When We Launch!</small>
                </div>
                <form onSubmit={handleSubmit}>
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Your Name
                  </label>
                  <FormControl className="w-full px-4 py-6">
                    <Input
                      fullWidth
                      label="Your Name"
                      name="name"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Name"
                      defaultValue={formInput.name}
                      helperText="Enter your full name"
                      onChange={handleInput}
                    />
                  </FormControl>
                  <label
                    className="block uppercase text-gray-700 text-xs font-bold mb-2 mt-5"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <FormControl className="w-full px-4 py-6 ">
                    <Input
                      fullWidth
                      label="Your Email"
                      name="email"
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      defaultValue={formInput.email}
                      helperText="Enter your email"
                      onChange={handleInput}
                    />
                  </FormControl>
                  <hr></hr>
                  <div className="text-center mt-6">
                    <button
                      className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="submit"
                    >
                      <EmailIcon></EmailIcon>
                      &nbsp;Join Wait List
                    </button>
                  </div>
                </form>
              </div>
              <ToastContainer
                position="bottom-center"
                autoClose={100000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
